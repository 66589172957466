import isEmpty from 'lodash/isEmpty';
const querystring = require('querystring');

export const xcApiCall = (api, postParams = {}, getParams = {}) => {
  let url = process.env.REACT_APP_BACKEND_API_BASE_URL + api.url;
  let fetchOptions = {
    method: api.method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  };

  // Get params
  if (isEmpty(getParams) === false) {
    url += '?' + querystring.stringify(getParams);
  }

  // Post params
  if (fetchOptions.method === 'POST' || fetchOptions.method === 'PUT') {
    fetchOptions.body = JSON.stringify(postParams);
  }

  const result = fetch(url, fetchOptions).then(res =>
    res
      .text()
      .then(text => ({
        headers: res.headers,
        status: res.status,
        text
      }))
      .then(({ headers, status, text }) => {
        // Only parse the text if we have some content
        var json = null;
        if (text) {
          try {
            json = JSON.parse(text);
          } catch (e) {
            json = text;
          }
        }
        return { headers, status, json };
      })
      // We assume a HTTP code of 400+ is an error for all calls
      .then(({ headers, status, json }) => {
        if (status >= 400) {
          throw new FetchError('XC Api call error', json, status);
        }
        return { headers, status, json };
      })
      // Finally, send only back the json object
      .then(({ headers, status, json }) => json)
  );

  return result;
};

// Custom error for fetch() failures
class FetchError extends Error {
  constructor(message = '', data = '', code = '') {
    super();
    this.message = message;
    this.data = data;
    this.code = code;
  }
}

/**
 * List of all the available XC APIs
 */
export const xcApis = {
  auth: {
    login: {
      url: '/api/auth',
      method: 'POST'
    },
    remind: {
      url: '/api/auth/reminder',
      method: 'GET'
    },
    reset: {
      url: '/api/auth/reminder',
      method: 'POST'
    },
    logout: {
      url: '/api/auth/logout',
      method: 'GET'
    }
  },
  client: {
    signup: {
      url: '/client-api/client/client',
      method: 'POST'
    },
    signupValidateEmail: {
      url: '/client-api/client/valid',
      method: 'POST'
    },
    profile: {
      url: '/client-api/client/profile',
      method: 'GET'
    },
    update: {
      url: '/client-api/client',
      method: 'PUT'
    }
  },
  mission: {
    create: {
      url: '/client-api/mission',
      method: 'POST'
    },
    list: {
      url: '/client-api/mission/list',
      method: 'GET'
    },
    get: {
      url: '/client-api/mission',
      method: 'GET'
    },
    getExpertsCategories: {
      url: '/client-api/mission/experts-categories',
      method: 'GET'
    },
    getExpertWithProfile: {
      url: '/client-api/mission/expert-with-profile',
      method: 'GET'
    },
    valid: {
      url: '/client-api/mission/valid',
      method: 'PUT'
    },
    requestTranscript: {
      url: '/client-api/mission/request-transcript',
      method: 'POST'
    },
    setExtraComplianceExpertSelectedByClient: {
      url: '/client-api/mission/extra-compliance-expert-selected-by-client',
      method: 'PUT'
    },
    getInterviews: {
      url: '/client-api/mission/interview',
      method: 'GET'
    },
    getPricingElements: {
      url: '/client-api/mission/pricing-elements',
      method: 'GET'
    },
    updateInterview: {
      url: '/client-api/mission/interview',
      method: 'PUT'
    },
    createInterviewFollowupQuestion: {
      url: '/client-api/mission/interview-followup-question',
      method: 'POST'
    },
    advisoryProjects: {
      url: '/client-api/mission/advisory-projects',
      method: 'GET'
    },
    advisoryProject: {
      url: '/client-api/mission/advisory-project',
      method: 'PUT'
    },
    evaluations: {
      url: '/client-api/mission/evaluations',
      method: 'GET'
    },
    evaluation: {
      url: '/client-api/mission/evaluation',
      method: 'POST'
    },
    expertComplianceCheck: {
      url: '/client-api/mission/expert-client-compliance-check',
      method: 'PUT'
    },
    pendingProposal: {
      url: '/client-api/mission/pending-proposal',
      method: 'GET'
    },
    // 2024 version
    interviewProposalStep: {
      url: '/client-api/mission/interview-proposal-step',
      method: 'POST'
    },
    // 2024 version
    interviewProposalAcceptSlot: {
      url: '/client-api/mission/interview-proposal-accept-slot',
      method: 'POST'
    },
    // Legacy version
    proposal: {
      url: '/client-api/mission/proposal',
      method: 'POST'
    },
    // Legacy version
    proposalAccept: {
      url: '/client-api/mission/proposal-accept',
      method: 'POST'
    },
    proposalCancel: {
      url: '/client-api/mission/proposal-cancel',
      method: 'POST'
    },
    processedProposals: {
      url: '/client-api/mission/processed-proposals',
      method: 'GET'
    }
  },
  billing: {
    invoiceIndex: {
      url: '/client-api/billing',
      method: 'GET'
    }
  },
  page: {
    view: {
      url: '/client-api/page/page',
      method: 'GET'
    }
  },
  extra: {
    url: '/client-api/client/extra',
    method: 'GET'
  }
};
