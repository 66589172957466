import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import Loader from './components/common/Loader';
import { xcApis, xcApiCall } from './helpers/xcApi'
import { AuthContext } from './context/Context';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState();
  const [isCheckingUserProfile, setIsCheckingUserProfile] = useState(true);

  const value = {
    loggedInUser,
    setLoggedInUser
  }

  useEffect(() => {
    // Determine if the user is already logged in
    if(isCheckingUserProfile && loggedInUser == null) {
      xcApiCall(xcApis.client.profile)
        .then(response => {
          // Success : we store the logged in user data
          setLoggedInUser(response)
          setIsCheckingUserProfile(false)
        })
        .catch(error => {
          // Error : we are not logged in
          setIsCheckingUserProfile(false)
        })
    }
  })

  // If we are still loading the user profile, we don't show anything yet
  if(isCheckingUserProfile) {
    return (
      <Loader />
    )
  }

  // Once the user profile is checked, we inject it and load the layout,
  // which will do the redirect to the login page if necessary
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthContext.Provider value={value}>
       <Layout />
      </AuthContext.Provider>
    </Router>
  );
};

export default App;
