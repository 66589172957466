

export const projectRoutes = {
  name: 'Projects',
  to: '/',
  exact: true,
  icon: 'copy'
};

export const accountRoutes = {
  name: 'My account',
  to: '/account',
  exact: true,
  icon: 'user'
};

export const BillingRoutes = {
  name: 'Billing',
  to: '/billing',
  exact: true,
  icon: 'file-archive'
}

export const ComplianceRoutes = {
  name: 'Terms and Compliance',
  to: '/terms-and-compliance',
  exact: true,
  icon: 'info'
}


export default [
  projectRoutes,
  accountRoutes,
  BillingRoutes,
  ComplianceRoutes
];
